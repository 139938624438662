import domReady from '@wordpress/dom-ready';
import { sprintf, _nx } from '@wordpress/i18n';

domReady(() => {
  const findstr = window.findstr;
  const countPersons = document.querySelector('.missing-persons-results-count');

  function adjustResultsCount(total) {
    const newText = sprintf(
      /* translators: %s is number of results */
      _nx('%s person', '%s persons', total, 'listing results count', 'vtx'),
      total
    );
    countPersons.textContent = newText;
  }

  if (findstr && countPersons) {
    findstr.hooks.addAction(
      'searchResults',
      'laval-missing-persons',
      (results, group) => {
        if ('missing-persons' === group) {
          setTimeout(adjustResultsCount(results.totalHits), 300);
        }
      }
    );
  }
});
